
import { defineComponent, ref } from "vue";
import firebase from 'firebase'
import { useToast } from "vue-toastification";
import axios from "axios";
import router from "@/router";

interface NewAddressData {
  targetTitle: string;
  assign: string;
  dueDate: string;
  targetDetails: string;
  tags: Array<string>;
}

export default defineComponent({
  name: "new-target-modal",
  components: {},
  methods: {
    posterError(e){
      useToast().error(e)
    },
    uploadPoster(e){
      this.uploading = true
      firebase.storage().ref('/posters').child(`${new Date().getTime()}`).put(e.target.files[0]).then(async snapshot=>{
        this.uploading = false
        this.video.poster = await snapshot.ref.getDownloadURL()
      }, err =>{
        this.uploading = false
        console.log('fuck error: ', err)
      })
    },
    uploadVideo(e){
      this.uploading_ = true
      firebase.storage().ref('/videos').child(`${new Date().getTime()}`).put(e.target.files[0]).then(async snapshot=>{
        this.uploading_ = false
        this.video.content = await snapshot.ref.getDownloadURL()
      }, err =>{
        this.uploading_ = false
        console.log('fuck error: ', err)
      })
    }
  },
  setup(props, {emit}) {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const uploading = ref<boolean>(false);
    const uploading_ = ref<boolean>(false);
    const limit = ref<boolean>(false);

    const video = ref({
      company: {
        name: 'MahalaAirtime',
        logo: 'https://www.mahalaairtime.com/assets/logo.7e0c49b1.svg'
      },
      title: "",
      description: "",
      poster: "",
      category: "",
      content: "",
      facebook: [],
      connections: [],
      feed: [],
      whatsapp: [],
      status: 'pending',
      uploaded: new Date(),
      users: [],
      limit: 200
    });

    const rules = ref({
      title: [
        {
          required: true,
          message: "Please input video name",
          trigger: "blur",
        },
      ]
    });

    const submit = () => {

      loading.value = true;

      if(uploading.value || uploading_.value){
        return setTimeout(submit, 5000)
      }else{

        if(video.value.poster == ''){
          loading.value = false
          return useToast().warning('Please upload the poster before proceeding.')
        }

        if(video.value.content == ''){
          loading.value = false
          return useToast().warning('Please upload the video file before proceeding.')
        }

        if(limit.value){
          if(video.value.limit == 0){
            loading.value = false
            return useToast().warning('Please specify limit of responses to auto terminate video.')
          }
        }else{
          video.value.limit = 0
        }

        axios.post('/videos', video.value).then(res=>{
          loading.value = false;
          useToast().success(res.data.message)
          document.getElementById('modal-close').click()
          emit('refresh', true)
        }).catch(e=>{
          loading.value = false;
          try{
            if(e.response.status == 401){
              useToast().warning('Your session has expired, please reauthenticate')
              return router.replace('/sign-in')
            }else{
              useToast().error(e.response.data.message)
            }
          }catch(err){
            useToast().error(e.message)
          }
        })
      }
    };

    return {
      video,
      submit,
      loading,
      uploading,
      uploading_,
      limit,
      formRef,
      rules,
    };
  },
});
