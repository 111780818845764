
import { defineComponent, ref } from "vue";
import NewVideoModal from "@/components/modals/forms/NewVideoModal.vue";
import Dropdown4 from "@/components/dropdown/Dropdown4.vue";
import { ScrollComponent } from "@/assets/ts/components";
import MessageIn from "@/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/components/messenger-parts/MessageOut.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "private-chat",
  components: {
    MessageIn,
    MessageOut,
    Dropdown4,
    NewVideoModal
  },
  data(){
    return {
      videos: [],
      filtered: [],
      answers: ['', ''],
      query: '',
      selected: null,
      loading: false,
      newMessageText: '',
      messagesRef: ref<null | HTMLElement>(null)
    }
  },
  methods: {
    getall(){
      this.axios.get('/videos').then(({data})=>{
        this.videos = data.videos
        this.filtered = data.videos
        this.getvideo(data.videos[0])
      }).catch(e=>{
        console.error(e)
      })
    },

    search(e){
      if(e.data != null){
        this.query += e.data
      }else{

        if(this.query.length > 0){
          this.query = this.query.substring(0, this.query.length-1)
        }
      }

      this.filtered = this.videos.filter(single=>single.title.toLowerCase().includes(this.query.toLowerCase()) || single.description.toLowerCase().includes(this.query.toLowerCase()))
    },
    getvideo(item){
      this.selected = null

      setTimeout(()=>{
        this.selected = item
      }, 1000)
    },

    stopvideo(){
      this.axios.delete(`/content/${this.selected.id}`).then(({data})=>{
        useToast().success(data.message)
        this.selected = null
        this.getall()
      }).catch(e=>{
        try{
          if(e.response.status == 401){
            useToast().warning('Your session has expired, please log back in')
            this.$router.replace('/sign-in')
          }else{
            useToast().error(e.response.data.message)
          }
        }catch(err){
          useToast().warning(e.message)
        }
      })
    },

    startvideo(){
      // this.filtered = this.videos.filter(single=>single.id!=this.selected.id)
      // this.selected = this.filtered[0]
      this.axios.post('/videos/status', {
        id: this.selected.id,
        status: 'active'
      }).then(({data})=>{
        useToast().success(data.message)
        this.getall()
      }).catch(e=>{
        try{
          if(e.response.status == 401){
            useToast().warning('Your session has expired, please log back in')
            this.$router.replace('/sign-in')
          }else{
            useToast().error(e.response.data.message)
          }
        }catch(err){
          useToast().warning(e.message)
        }
      })
    },
  },
  mounted(){
    ScrollComponent.bootstrap()
    this.getall()
    setCurrentPageTitle('Videos')
  }
});
